import Vue from 'vue';
Vue.config.devtools = true;
import Vuex from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import 'firebase/compat/firestore';
//import * as moment from 'moment';

import { db } from '../main.js';

Vue.use(Vuex);
//Vue.use(require('vue-moment'));

export const store = new Vuex.Store({
  state: {
    user: null,
    count: 0,
    isUserAdmin: false,
    myVessels: [],
    myCrew: [],
    myJobs: [],
    selectedVessel: '',
    currentVesselName: '',
    currentVesselID: '',
    currentVesselUVI: '',
    currentVesselPlans: [],
    currentVesselIsCompanyVessel: false,
    smsStatus: {
      overview: false,
      duty: false,
      master: false,
      designated: false,
      owner: false,
      hazards: false,
      smscompanyhazards: false,
      defects: false,
      inductions: false,
      smscontacts: false,
      companycontacts: false,
      smspolicies: false,
      companypolicies: false,
      smssops: false,
      companysops: false,
      smseops: false,
      companyeops: false,
      smsmaintenance: false,
      companymaintenance: false,
      smsstartups: false,
      companystartups: false,
      smsactivities: false,
      companyactivities: false,
      smsshutdowns: false,
      companyshutdowns: false,
      vesselinductionitems: false,
      companyinductionitems: false,
      auditorFeedback: false,
      auditorFeedbackCount: 0,
      amsaAuditsPending: 0,
    },
    demoSmsStatus: {
      overview: false,
      duty: false,
      master: false,
      designated: false,
      hazards: false,
      inductions: false,
      smscontacts: false,
      smspolicies: false,
      smssops: false,
      smseops: false,
      smsmaintenance: false,
      smsstartups: false,
      vesselinductionitems: false,
    },
    currentLocation: '',
    companyStatus: {
      policies: 0,
      sops: 0,
      eops: 0,
      contacts: 0,
      inductions: 0,
      maintenance: 0,
      startups: 0,
      activities: 0,
      shutdowns: 0,
      count: false,
      maxVessels: 0,
    },
    subscriptionInfo: {
      currentMaxVessels: 0,
      anniversaryDate: '',
      nextCharge: 0,
      test: '',
      userrole: '',
    },
    myCurrentVesselIDs: [],
    myActiveVessels: [],
    myActiveVesselIDs: [],
    //seenNotice: true,
    myVesselsWithCurrentDefects: 0,

    running: false,
    runningTime: '00:00:00',
    timeBegan: null,
    timeStopped: null,
    randomCode: 0,

    // new sea time page
    timerRunning: false,
    timerOpen: false,
    timerOpenTimestamp: '',
    timerStart: '',
    timerStop: '',
    timerTotal: '',

    onlineStatus: true,
    myCurrentVesselData: [],
    dataStatus: true,

    
    


    // NEW STATE ITEMS: ===
    userProfile: {},
    myCrewedVesselData: [],
    allCrewedVessels: [],
    currentLogVessel: {
      vesselName: '',
      vesselUVI: '',
      vesselID: '',
    },
    currentStartupItems: {},
    allMyVesselCrew: {},
    divePlan: [],
    vesselInductionChecklist: [],
    allMyCrew: [],
    allMyJobs: [],
    myTrainers: [],
    stateLoaded: false,
    isDemoVessel: false,
    isAuditor: false,
    myCompanies: [],
    currentCompanyName: '',
    currentCompanyID: '',
    currentCompany: {
      name: '',
      id: ''
    },
    currentCompanyVessel: {},
    currentVessel: {},

  },
  getters: {
    getUser: state => {
      return state.user;
    },
    getIsUserAdmin: state => {
      return state.isUserAdmin;
    },
    getIsAuditor: state => {
      return state.isAuditor;
    },
    getMyCrew: state => {
      return state.myCrew;
    },
    getMyJobs: state => {
      return state.myJobs;
    },
    getSelectedVessel: state => {
      return state.selectedVessel;
    },
    getCurrentVessel: state => {
      return state.currentVessel;
    },
    getCurrentVesselName: state => {
      return state.currentVesselName;
    },
    getCurrentVesselID: state => {
      return state.currentVesselID;
    },
    getCurrentVesselUVI: state => {
      return state.currentVesselUVI;
    },
    getCurrentVesselPlans: state => {
      return state.currentVesselPlans;
    },
    getCurrentVesselIsCompanyVessel: state => {
      return state.currentVesselIsCompanyVessel;
    },
    getIsDemoVessel: state => {
      return state.isDemoVessel;
    },
    getSmsStatus: state => {
      return state.smsStatus;
    },
    getDemoSmsStatus: state => {
      return state.demoSmsStatus;
    },
    getCompanyStatus: state => {
      return state.companyStatus;
    },
    // getSeenNotice: state => {
    //   return state.seenNotice;
    // },
    getCurrentLocation: state => {
      return state.currentLocation;
    },
    getSubscriptionInfo: state => {
      return state.subscriptionInfo;
    },
    getUserRole: state => {
      return state.subscriptionInfo.userrole;
    },
    getMyCurrentVesselIDs: state => {
      return state.myCurrentVesselIDs;
    },
    getMyCurrentVesselData: state => {
      return state.myCurrentVesselData;
    },
    getMyActiveVessels: state => {
      return state.myActiveVessels;
    },
    getMyActiveVesselIDs: state => {
      return state.myActiveVesselIDs;
    },
    getMyVesselsWithCurrentDefects: state => {
      return state.myVesselsWithCurrentDefects;
    },
    getRunningTime: state => {
      return state.runningTime;
    },
    getTimeBegan: state => {
      return state.timeBegan;
    },
    getTimeStopped: state => {
      return state.timeStopped;
    },
    getRandomCode: state => {
      return state.randomCode;
    },
    getOnlineStatus: state => {
      return state.onlineStatus;
    },
    getDataStatus: state => {
      return state.dataStatus;
    },
    // new sea time page
    getIsTimerRunning: state => {
      return state.timerRunning;
    },
    getIsTimerOpen: state => {
      return state.timerOpen;
    },
    getIsTimerOpenTimestamp: state => {
      return state.timerOpenTimestamp;
    },
    getTimerStart: state => {
      return state.timerStart;
    },
    getTimerStop: state => {
      return state.timerStop;
    },
    getTimerTotal: state => {
      return state.timerTotal;
    },
    getMyCompanies: state => {
      return state.myCompanies;
    },
    getCurrentCompany: state => {
      return state.currentCompany;
    },
    getCurrentCompanyVessel: state => {
      return state.currentCompanyVessel;
    },





    // NEW GETTERS: =========================

    getDashboardLoaded: state => {
      return state.stateLoaded;
    },
    getUserProfile: state => {
      return state.userProfile;
    },
    getMyVessels: state => {
      return state.myVessels;
    },
    getMyCrewedVesselData: state => {
      return state.myCrewedVesselData;
    },
    getAllCrewedVessels: state => {
      return state.allCrewedVessels;
    },
    getCurrentLogVessel: state => {
      return state.currentLogVessel;
    },
    getCurrentStartupItems: state => {
      return state.currentStartupItems;
    },
    getAllVesselCrew: state => {
      return state.allMyVesselCrew;
    },
    getDivePlan: state => {
      return state.divePlan;
    },
    getVesselInduction: state => {
      return state.vesselInductionChecklist;
    },
    getUserTestData: state => {
      return state.userProfile.username;
    },
    getAllMyCrew: state => {
      return state.allMyCrew;
    },
    getAllMyJobs: state => {
      return state.allMyJobs;
    },
    getMyTrainers: state => {
      return state.myTrainers;
    },

    


  },
  mutations: {

    // ITEMS BELOW ARE NEWLY UPDATED :

    LOADDASHBOARD: state => {
      state.stateLoaded = state.myVessels.length ? true : false;
    },

    SETUSERPROFILE: state => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            state.userProfile = doc.data();
          }
        });
      }
    },

    SETMYVESSELS: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            let vessels = doc.data().myVessels;
            if (vessels) {
              state.myVessels = [];
              vessels.forEach(function(vessel) {
                state.myVessels.push({
                  "vesselID" : vessel.vesselID,
                  "vesselName" : vessel.vesselName,
                  "vesselUVI" : vessel.vesselUVI,
                });
              });
            }
            state.currentMaxVessels = (doc.data().maxVessels ? doc.data().maxVessels : 0);
          }
        });
      }
    },


    MYCREWEDVESSELDATA: (state) => {
      db.collection('userdata').doc(state.user.uid).collection('vesselsByCompany').onSnapshot(function(querySnapshot) {
      state.myCrewedVesselData = [];
        querySnapshot.forEach(function(doc) {
          if (doc.data().vesselList) {
            doc.data().vesselList.forEach(function(vessel) {
                state.myCrewedVesselData.push({
                  vesselID: vessel.vesselID,
                  vesselUVI: vessel.vesselUVI,
                  vesselName: vessel.vesselName,
                  isCrew: vessel.isCrew ? vessel.isCrew : false,
                  isMaster: vessel.isMaster ? vessel.isMaster : false,
                });
            });
          }
        });
      });
    },

    UPDATEALLCREWEDVESSELS: (state) => {
      // let crewVessels = [];
      // let masterVessels = [];
      state.allCrewedVessels = [];
      db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
        state.allCrewedVessels = [];
        let crewVessels = [];
        let masterVessels = [];
        crewVessels = doc.data().vesselsThatICrew ? doc.data().vesselsThatICrew : [];
        masterVessels = doc.data().vesselsThatIMaster ? doc.data().vesselsThatIMaster : [];

        // console.log('ALL',crewVessels,masterVessels);

        if (crewVessels.length > 0) {

              let tempCrewedVessels = [];

              const getVessels = async () => {
                // state.allCrewedVessels = [];
                tempCrewedVessels = [];
                for (const vessel of crewVessels) {
                  let findVesselData = await getSingleVesselName(vessel);
                  // state.allCrewedVessels.push({
                  tempCrewedVessels.push({
                    vesselID: vessel,
                    vesselName: findVesselData[0],
                    vesselUVI: findVesselData[1],
                    isCompanyVessel: findVesselData[2],
                    lastStartupID: findVesselData[3],
                    isActive: findVesselData[4],
                    vesselOwnerID: findVesselData[5],
                    showLogs: masterVessels.includes(vessel) ? true : false,
                    doesDiving: findVesselData[6],
                    takesPassengers: findVesselData[7],
                  });                  
                }
              }

              const getSingleVesselName = vessel => {
                return db.collection('vessels').doc(vessel).get().then(function(doc) {
                  //console.log(doc.data().vesselName);
                  return [
                    doc.data().vesselName, 
                    doc.data().vesselUVI, 
                    doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false, 
                    doc.data().lastStartupID ? doc.data().lastStartupID : '',
                    doc.data().isActive ? doc.data().isActive : false, 
                    doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '',
                    doc.data().doesDiving ? doc.data().doesDiving : false, 
                    doc.data().takesPassengers ? doc.data().takesPassengers : false, 
                  ];
                });
              }

              getVessels().then(() => {
                //console.log(tempCrewedVessels);
                state.allCrewedVessels = tempCrewedVessels;
                //console.log(state.allCrewedVessels);
              });

        }

        // if (crewVessels.length > 0) {

        //       let tempCrewedVessels = [];

        //       const getVessels = async () => {
        //         //state.allCrewedVessels = [];
        //         tempCrewedVessels = [];
        //         for (const vessel of crewVessels) {
        //           //let findVesselData = await getSingleVesselName(vessel);
        //           // state.allCrewedVessels.push({
        //           tempCrewedVessels.push({
        //             vesselID: vessel,
        //             //vesselName: findVesselData[0],
        //             //vesselUVI: findVesselData[1],
        //             //isCompanyVessel: findVesselData[2],
        //             showLogs: masterVessels.includes(vessel) ? true : false,
        //           });                  
        //         }
        //       }

        //       // const getSingleVesselName = vessel => {
        //       //   return db.collection('vessels').doc(vessel).get().then(function(doc) {
        //       //     console.log(doc.data().vesselName);
        //       //     return [doc.data().vesselName,doc.data().vesselUVI,doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false];
        //       //   });
        //       // }

        //       getVessels().then(() => {
        //         console.log(tempCrewedVessels);
        //         state.allCrewedVessels = tempCrewedVessels;
        //         console.log(state.allCrewedVessels);
        //       });

        // }



      });
    },

    SETALLMYCREW: (state) => {
      state.allMyCrew = [];
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).collection('myCrewConnections').onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                state.allMyCrew.push({
                  id: doc.data().crewID,
                  name: doc.data().crewName ? doc.data().crewName : doc.data().connectionName,
                });
            });
        });        
        db.collection('userdata').doc(state.user.uid).collection('myTemporaryCrew').onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                state.allMyCrew.push({
                  id: doc.id,
                  name: doc.data().crewName,
                });
            });
        });
      }
    },

    SETALLMYJOBS: state => {
      db.collection('userdata').doc(state.user.uid).collection('myJobConnections').onSnapshot(function(querySnapshot) {
          state.allMyJobs = [];
          querySnapshot.forEach(function(doc) {
            state.allMyJobs.push({
              id: doc.id,
              jobID: doc.data().jobID,
              name: doc.data().crewName ? doc.data().crewName : doc.data().connectionName,
              //inductionID: doc.data().inductionID ? doc.data().inductionID : ''
            });
          });
      });
    },  

    SETMYTRAINERS: state => {
      db.collection('userdata').doc(state.user.uid).collection('myTrainerConnections').onSnapshot(function(querySnapshot) {
        state.myTrainers = [];
        querySnapshot.forEach(function(doc) {
          // state.myTrainers.push({
          //   trainerName: doc.data().trainerName,
          //   trainerID: doc.data().trainerID,
          //   trainerOrganisation: doc.data().trainerOrganisation,
          //   connectionID: doc.data().connectionID,
          //   vesselID: doc.data().studentVesselID,
          //   vesselName: (doc.data().studentVesselName == '' ? 'None' : doc.data().studentVesselName),
          //   vesselUVI: doc.data().studentVesselUVI
          // });
          state.myTrainers.push(doc.data());
        });
      });
    },

    SETCURRENTLOGVESSEL: (state, payload) => {
      state.currentLogVessel.vesselName = payload.vesselName;
      state.currentLogVessel.vesselUVI = payload.vesselUVI;
      state.currentLogVessel.vesselID = payload.vesselID;
    },

    LOADVESSELSTARTUPITEMS: (state) => {
      state.currentStartupItems = {};
      state.myVessels.forEach(function(vessel) {

          let tempObject = [];
          db.collection("vessels").doc(vessel.vesselID).collection("smsStartupItems")
          .onSnapshot(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  tempObject.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: (doc.data().details ? doc.data().details : ''),
                  });
              });
          });
          db.collection("vessels").doc(vessel.vesselID).collection("smsCompanyStartupItems")
          .onSnapshot(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  tempObject.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: (doc.data().details ? doc.data().details : ''),
                  });
              });
          });
          state.currentStartupItems[vessel.vesselID] = tempObject;

      });

      state.myCrewedVesselData.forEach(function(vessel) {

          let tempObject = [];
          db.collection("vessels").doc(vessel.vesselID).collection("smsStartupItems")
          .onSnapshot(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  tempObject.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: (doc.data().details ? doc.data().details : ''),
                  });
              });
          });
          db.collection("vessels").doc(vessel.vesselID).collection("smsCompanyStartupItems")
          .onSnapshot(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  tempObject.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: (doc.data().details ? doc.data().details : ''),
                  });
              });
          });
          state.currentStartupItems[vessel.vesselID] = tempObject;

      });

    },

    LOADALLVESSELCREW: (state) => {
      // console.log('Loading crew...');
      // console.log(state.myCrewedVesselData);
      state.allMyVesselCrew = {};

      state.myVessels.forEach(function(vessel) {

          let tempObject = [];
          let inductedCrew = [];          
          // first get list of all inducted crew for each vessel
          db.collection("vessels").doc(vessel.vesselID).collection("vesselCrew").get()
          .then(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  let dueDate = new Date(doc.data().dueDate.toDate());
                  // let isPast = new Date() > dueDate ? true : false;
                  // if (!isPast) { inductedCrew.push(doc.id); }
                  inductedCrew.push(doc.id);
              });
          })
          .then(function() {
              db.collection('userdata').doc(state.user.uid).collection('myCrewConnections').get()
              .then(function(querySnapshot) {
                  querySnapshot.forEach(function(doc) {
                      tempObject.push({
                        id: doc.data().crewID,
                        name: doc.data().crewName ? doc.data().crewName : doc.data().connectionName,
                        inductionDue: !inductedCrew.includes(doc.data().crewID) ? true : false
                      });
                  });
              })     
              .then(function(){
                db.collection('userdata').doc(state.user.uid).collection('myTemporaryCrew').get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        tempObject.push({
                          id: doc.id,
                          name: doc.data().crewName,
                          inductionDue: !inductedCrew.includes(doc.id) ? true : false
                        });
                    });
                });
              })         
              .then(function(){
                  tempObject.push({
                    id: state.user.uid,
                    name: state.userProfile.username,
                    inductionDue: !inductedCrew.includes(state.user.uid) ? true : false
                  });
              });

          }).then(function() {
            state.allMyVesselCrew[vessel.vesselID] = tempObject;
          });

      });

      state.myCrewedVesselData.forEach(function(vessel) {

          let tempObjectB = [];
          let inductedCrewB = [];  
          let crewArray = [];   

          db.collection("vessels").doc(vessel.vesselID).collection("vesselCrew").get()
          .then(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                  let dueDate = new Date(doc.data().dueDate.toDate());
                  let isPast = new Date() > dueDate ? true : false;
                  if (!isPast) { inductedCrewB.push(doc.id); }
              });
          })
          .then(function() {
              let ownerID;     
              db.collection('vessels').doc(vessel.vesselID).get()
              .then(function(doc) {
                  if (doc.exists) {
                     ownerID = doc.data().vesselOwnerID;
                   }
               })
              .then(function() {
                  db.collection('userdata').doc(ownerID).collection('myCrewConnections').get()
                  .then(function(querySnapshot) {
                      querySnapshot.forEach(function(doc) {
                          tempObjectB.push({
                            id: doc.data().crewID,
                            name: doc.data().crewName ? doc.data().crewName : doc.data().connectionName,
                            inductionDue: !inductedCrewB.includes(doc.data().crewID) ? true : false
                          });
                          crewArray.push(doc.data().crewID);
                      });
                  })
              })
              .then(function(){
                  db.collection('userdata').doc(ownerID).collection('myTemporaryCrew').get()
                  .then(function(querySnapshot) {
                      querySnapshot.forEach(function(doc) {
                          tempObjectB.push({
                            id: doc.id,
                            name: doc.data().crewName,
                            inductionDue: !inductedCrewB.includes(doc.id) ? true : false
                          });
                          crewArray.push(doc.id);
                      });
                  }) ;       
                  // .then(function() {
                  //     //console.log(crewArray);
                  //     crewArray.forEach(function(crew) {
                  //       console.log(crew,state.user.uid);
                  //         if (crew !== state.user.uid) {
                  //           console.log('not unique');
                  //           tempObjectB.push({
                  //             id: state.user.uid,
                  //             name: state.userProfile.username,
                  //             inductionDue: !inductedCrewB.includes(state.user.uid) ? true : false
                  //           });
                  //         }
                  //     });
                  // });
              });
          }).then(function() {
            state.allMyVesselCrew[vessel.vesselID] = tempObjectB;
          });         


      });


    },

    LOADDIVEPLAN: (state) => {
      state.divePlan = [];
      if (state.userProfile.doesDiving) {
        db.collection('userdata').doc(state.user.uid).collection('diveChecklists').onSnapshot(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            state.divePlan.push({
              id: doc.id,
              title: (doc.data().title ? doc.data().title : ''),
              details: (doc.data().details ? doc.data().details : ''),
            });
          });
        });
      }
    },

    SETVESSELINDUCTION: (state, payload) => {
      //console.log(payload.vesselID);
      db.collection('vessels').doc(payload.vesselID).collection('smsVesselInductionItems').onSnapshot(function(querySnapshot) {
        state.vesselInductionChecklist = [];
        querySnapshot.forEach(function(doc) {
          //console.log(doc.data());
          state.vesselInductionChecklist.push({
            id: doc.id,
            title: doc.data().title,
            details: (doc.data().details ? doc.data().details : '')
          });
        });   
      });
      //console.log(state.vesselInductionChecklist);
    },















    // ITEMS BELOW ARE OLD ==================

    RESETSTATES: state => {
      state.user = null;
      state.count = 0;
      //state.isUserAdmin = false; // must this be reset ?????
      state.myVessels = [];
      state.myCrew = [];
      state.myJobs = [];
      state.subscriptionInfo = {
        currentMaxVessels: 0,
        anniversaryDate: '',
        nextCharge: 0,
        test: ''
      };
    },

    SETONLINESTATUS: state => {
      navigator.onLine ? state.onlineStatus = true : state.onlineStatus = false;
      window.addEventListener('online', () => {
        state.onlineStatus = true;
      });
      window.addEventListener('offline', () => {
        state.onlineStatus = false;
      });
    },

    UPDATEUSERSTATE: state => {
      var user = firebase.auth().currentUser;
      if (user) {
          //console.log("1: ", user.uid);
        // user.getIdTokenResult(true) 
        //   .then((idTokenResult) => {
        //   if (idTokenResult.claims.isAdmin) {
        //     state.isUserAdmin = true;
        //   } else {
        //     state.isUserAdmin = false;
        //   }
        // });
        // alternative test for user admin => check if starter or fleet user role
        db.collection('userdata').doc(user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            state.isUserAdmin = (doc.data().userrole == 'free' ? false : true);
            state.subscriptionInfo.userrole = doc.data().userrole ? doc.data().userrole : '';
            //console.log('Set isUserAdmin: :',state.isUserAdmin);
          }
        });
      }
    },

    SETUSER: state => {
      state.user = firebase.auth().currentUser;
      // console.log(state.user);
    },

    setIsAdmin: state => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // alternative test for user admin => check if starter or fleet user role
          db.collection('userdata').doc(user.uid).onSnapshot(function(doc) {
            if (doc.exists) {
              state.isUserAdmin = doc.data().userrole == 'free' ? false : true;
              state.isAuditor = doc.data().isAuditor ? true : false;
              //console.log('Set isUserAdmin: :',state.isUserAdmin);
            }
          });

        }
      });            
    },

    SETMYVESSELSWITHCURRENTDEFECTS: (state) => {

      state.myVesselsWithCurrentDefects = 0;
      db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
        if (doc.data().myVessels) {
          doc.data().myVessels.forEach(function(vessel) {
            db.collection('vessels').doc(vessel.vesselID).collection('smsDefects').onSnapshot(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                if (!doc.data().resolved) {
                  //console.log(doc.data());
                  state.myVesselsWithCurrentDefects++;
                }
              });
            });
          });
        }
      });

    },

    MYCURRENTVESSELIDS: (state) => {

      db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
        state.myCurrentVesselIDs = [];
        if (doc.data().myVessels) {
          doc.data().myVessels.forEach(function(vessel) {
            state.myCurrentVesselIDs.push(vessel.vesselID);
          });
        }
      });

    },

    MYCURRENTVESSELDATA: (state) => {
      db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
        state.myCurrentVesselData = [];
        if (doc.data().myVessels) {
          doc.data().myVessels.forEach(function(vessel) {
            db.collection('vessels').doc(vessel.vesselID).onSnapshot(function(item) {
                state.myCurrentVesselData.push({
                  vesselID: vessel.vesselID,
                  vesselName: item.data().vesselName,
                });
            });
          });
        }
      });

    },

    MYACTIVEVESSELS: (state) => {
      // console.log('committed',state.currentCompany.id);
      state.myActiveVessels = [];
      state.myActiveVesselIDs = [];

      if (state.currentCompany.id) {
        db.collection('companies').doc(state.currentCompany.id).collection('companyVessels').onSnapshot(function(querySnapshot) {
          // console.log(state.currentCompany.id);
          state.myActiveVessels = [];
          state.myActiveVesselIDs = [];
          
          querySnapshot.forEach((vessel) => {
            // console.log(vessel.id);
            db.collection('vessels').doc(vessel.id).get().then(function(item) {
              //console.log(item.data());
              if (item.data().isActive) {
                //console.log(item.data().vesselName);
                let tempDate =  item.data().lastStartupDate;
                let finalDate;
                // let isString = typeof tempDate === 'string';
                // console.log('isString',isString);
                if (typeof tempDate === 'string') {
                  finalDate = tempDate.substr(0, tempDate.indexOf(',')); 
                } else {
                  finalDate = tempDate.toDate();
                }
                // console.log('Final Date',finalDate);
                // console.log(Date.parse(tempDate));
                // var convertedDate = tempDate.substr(0, tempDate.indexOf(',')); 
                // console.log(convertedDate);


                state.myActiveVessels.push({
                  id: item.id,
                  isActive: item.data().isActive,
                  vesselName: item.data().vesselName,
                  vesselUVI: item.data().vesselUVI,
                  lastStartupID: item.data().lastStartupID ? item.data().lastStartupID : '',
                  // lastStartupDate: item.data().lastStartupDate ? item.data().lastStartupDate : '',
                  lastStartupDate: finalDate,
                  //crewOnboard: item.data().crewOnboard
                });
                state.myActiveVesselIDs.push(item.id);
              }
            });
          });

        });
      } else {
        console.log('No company..');
      }

      // if (state.user.uid) {
      //   db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
      //     if (doc.exists) {
      //       if (doc.data().myVessels) {
      //         doc.data().myVessels.forEach(function(vessel) {
      //           db.collection('vessels').doc(vessel.vesselID).get().then(function(item) {
      //             if (item.data().isActive) {
      //               state.myActiveVessels.push({
      //                 id: item.id,
      //                 isActive: item.data().isActive,
      //                 vesselName: item.data().vesselName,
      //                 lastStartupID: (item.data().lastStartupID ? item.data().lastStartupID : ''),
      //                 lastStartupDate: (item.data().lastStartupDate ? item.data().lastStartupDate : ''),
      //                 //crewOnboard: item.data().crewOnboard
      //               });
      //               state.myActiveVesselIDs.push(item.id);
      //             }
      //           });
      //         });
      //       }
      //     }
      //   });
      // }

      // db.collection('userdata').doc(state.user.uid).collection('vesselsByCompany').onSnapshot(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     if (doc.data().vesselList) {
      //       doc.data().vesselList.forEach(function(vessel) {
      //         db.collection('vessels').doc(vessel.vesselID).get().then(function(item) {
      //           if (item.data().isActive) {
      //             state.myActiveVessels.push({
      //               id: item.id,
      //               isActive: item.data().isActive,
      //               vesselName: item.data().vesselName,
      //               lastStartupID: (item.data().lastStartupID ? item.data().lastStartupID : ''),
      //               lastStartupDate: (item.data().lastStartupDate ? item.data().lastStartupDate : ''),
      //               //crewOnboard: item.data().crewOnboard
      //             });
      //             state.myActiveVesselIDs.push(item.id);
      //           }
      //         });
      //       });
      //     }
      //   });
      // });


    },

    SETSUBSCRIPTIONINFO: state => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(documentSnapshot) {
          if (documentSnapshot.exists) {
            var data = documentSnapshot.data(); 
            //console.log(data);
            //console.log(data.anniversaryDate.toDate());
            let tempDate = (data.anniversaryDate ? data.anniversaryDate.toDate() : '');
            let stringDate = String(tempDate);
            //console.log(data.anniversaryDate.seconds);
            state.subscriptionInfo.currentMaxVessels = (data.maxVessels ? data.maxVessels : 0);
            //state.subscriptionInfo.anniversaryDate = (data.anniversaryDate ? data.anniversaryDate.seconds : 0);
            state.subscriptionInfo.anniversaryDate = stringDate;
            state.subscriptionInfo.userid = data.userid;
            state.subscriptionInfo.nextCharge = (data.nextCharge ? data.nextCharge : 0);
            state.subscriptionInfo.test = stringDate;
            state.subscriptionInfo.homeport =  data.homeport ? data.homeport : '';
            state.subscriptionInfo.industry =  data.industry ? data.industry : '';
            state.subscriptionInfo.userrole =  (data.userrole ? data.userrole : 0);
            state.subscriptionInfo.isTrainer = (data.isTrainer ? data.isTrainer : false);
            state.subscriptionInfo.isAuditor = data.isAuditor ? data.isAuditor : false;
            state.subscriptionInfo.auditorState = data.auditorState ? data.auditorState : 'Not set';
            state.subscriptionInfo.doesDiving = (data.doesDiving ? data.doesDiving : false);
            state.subscriptionInfo.takesPassengers = (data.takesPassengers ? data.takesPassengers : false);
            state.subscriptionInfo.trainerOrganisation = (data.trainerOrganisation ? data.trainerOrganisation : 'not set');
            state.subscriptionInfo.trainerDefaultState = (data.trainerDefaultState ? data.trainerDefaultState : false);
            state.subscriptionInfo.trainerDefaultIndustry = (data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false);
            state.subscriptionInfo.trainerDefaultIndustry = (data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false);
            state.subscriptionInfo.username = (data.username ? data.username : 'No name');
            state.subscriptionInfo.useremail = (data.useremail ? data.useremail : '');
            state.subscriptionInfo.userphone = (data.userphone ? data.userphone : '');
            state.subscriptionInfo.userstate = (data.userstate ? data.userstate : '');
            state.subscriptionInfo.takenDashboardTour = data.takenDashboardTour ? data.takenDashboardTour : false;
            state.subscriptionInfo.takenFleetTour = data.takenFleetTour ? data.takenFleetTour : false;
            state.subscriptionInfo.seenAppPrompt = (data.seenAppPrompt ? data.seenAppPrompt : false);
            state.subscriptionInfo.seenAppPromptDate = (data.seenAppPromptDate ? data.seenAppPromptDate : '');
            // state.subscriptionInfo.timerRunning = (data.timerRunning ? data.timerRunning : false);
          }
          //console.log(state.subscriptionInfo.anniversaryDate)
        });
      }
    },

    CLEARCREW: state => {
      state.myCrew = [];
    },
    SETMYCREW: (state) => {
      //state.myCrew = [];
      var documentReference = db.collection('userdata').doc(state.user.uid);
      documentReference.onSnapshot(function(doc) {
          //console.log("Current data: ", doc.data());
          if (doc.data().myConnections) {
              //console.log(doc.data().myConnections.length);
              state.myCrew = [];
              doc.data().myConnections.forEach((crew) => {
                let subConnRef = db.collection('userdata').doc(state.user.uid).collection('myConnections').doc(crew);
                subConnRef.get().then(function(subDoc){
                  if (subDoc.exists) {
                    //console.log("set my crew");
                    //console.log("Sub document data for ",state.user.uid ,":", subDoc.data());
                    state.myCrew.push({
                        "connectionID" : crew,
                        "crewName" : subDoc.data().connectionName,
                        "crewStatus" : subDoc.data().connectionStatus
                    })
                  }
                })
              });
          }
      });
    },
    SETMYJOBS: (state) => {

      var documentReference = db.collection('userdata').doc(state.user.uid);
      documentReference.onSnapshot(function(doc) {
          if (doc.data().myConnections) {
              state.myJobs = [];
              doc.data().myConnections.forEach((job) => {
                let subConnRef = db.collection('userdata').doc(state.user.uid).collection('myConnections').doc(job);
                subConnRef.get().then(function(subDoc){
                  if (subDoc.exists) {
                    //console.log("set my jobs");
                    //console.log("Sub document data for ",state.user.uid ,":", subDoc.data());
                    state.myJobs.push({
                        "connectionID" : job,
                        "jobName" : subDoc.data().connectionName,
                        "jobStatus" : subDoc.data().connectionStatus
                    })
                  }
                })
              });
          }
      });

    },
    addNewVessel: (state, payload) => {
      state.myVessels.push({
        "vesselID" : payload.vesselID,
        "vesselName" : payload.vesselName,
        "vesselUVI" : payload.vesselUVI
      });
    },
    ADDNEWCREW: (state, payload) => {
      state.myCrew.push({
        //"crewID" : payload.crewID,
        "crewName" : payload.crewName,
        "crewStatus" : payload.crewStatus
      });
    },
    increment: state => state.count++,
    decrement: state => state.count--,
    SETSELECTEDVESSEL: (state, payload) => {
      // console.log("INSIDE: ",payload.vesselName,payload.vesselID,payload.vesselUVI,payload.aaaPlans);
      state.currentVesselName = payload.vesselName;
      state.currentVesselID = payload.vesselID;
      state.currentVesselUVI = payload.vesselUVI;
      state.currentVesselPlans = payload.aaaPlans;
      state.currentVesselIsCompanyVessel = payload.isCompanyVessel;
      state.selectedVessel = {
        vesselName: payload.vesselName ? payload.vesselName : '',
        vesselID: payload.vesselID ? payload.vesselID : '',
        vesselUVI: payload.vesselUVI ? payload.vesselUVI : '',
        takesPassengers: payload.vesselTakesPassengers ? payload.vesselTakesPassengers : false,
        aaaPlans: payload.aaaPlans ? payload.aaaPlans : [],
        isCompanyVessel: payload.isCompanyVessel ? payload.isCompanyVessel : false,
      }
    },
    SETCURRENTVESSEL: (state, payload) => {
      // console.log(payload.currentVessel);
      state.currentVessel = payload.currentVessel;
    },
    SETSELECTEDCOMPANYVESSEL: (state, payload) => {
      state.currentCompanyVessel = {
        vesselName: payload.vesselName,
        vesselID: payload.vesselID,
        vesselUVI: payload.vesselUVI
      }
    },
    CLEARSELECTEDVESSEL: state => {
      state.currentVesselName = '';
      state.currentVesselID = '';
      state.currentVesselUVI = '';
    },
    SETDEMOVESSEL: (state, payload) => {
      state.isDemoVessel = payload.isDemoVessel;
    },

    SETCOMPANYSTATUS: state => {

      state.companyStatus.count = 0;

      // db.collection('userdata').doc(state.user.uid).collection('companyPolicies').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.policies = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companySOPS').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.sops = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyEOPS').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.eops = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyContacts').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.contacts = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyInductionItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.inductions = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyMaintenanceItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.maintenance = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyStartupItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.startups = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyActivityItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.activities = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyShutdownItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.shutdowns = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyAdditionalItems').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.additional = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });
      // db.collection('userdata').doc(state.user.uid).collection('companyHazards').onSnapshot(function(querySnapshot) {
      //     state.companyStatus.hazards = querySnapshot.size;
      //     if (querySnapshot.size > 0) state.companyStatus.count++; 
      // });

      // console.log('COMPANY:',state.currentCompany);

      if (state.currentCompany) {
        db.collection('companies').doc(state.currentCompany.id).collection('companyPolicies').onSnapshot(function(querySnapshot) {
            state.companyStatus.policies = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companySOPS').onSnapshot(function(querySnapshot) {
            state.companyStatus.sops = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyEOPS').onSnapshot(function(querySnapshot) {
            state.companyStatus.eops = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyContacts').onSnapshot(function(querySnapshot) {
            state.companyStatus.contacts = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyInductionItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.inductions = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyMaintenanceItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.maintenance = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyStartupItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.startups = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyActivityItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.activities = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyShutdownItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.shutdowns = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyJSAItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.jsas = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyAdditionalItems').onSnapshot(function(querySnapshot) {
            state.companyStatus.additional = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).collection('companyHazards').onSnapshot(function(querySnapshot) {
            state.companyStatus.hazards = querySnapshot.size;
            if (querySnapshot.size > 0) state.companyStatus.count++; 
        });
        db.collection('companies').doc(state.currentCompany.id).onSnapshot(function(doc) {
            state.companyStatus.maxVessels = doc.data().maxVessels ? doc.data().maxVessels : 0;
        });
      }

        


    },

    SETSMSSTATUS: state => {

      db.collection("vessels").doc(state.currentVesselID).onSnapshot(function(doc) {
        state.smsStatus.overview = doc.data().overviewSavedAt ? true : false;
        state.smsStatus.duty = doc.data().dutyCareAcceptedAt ? true : false;
        state.smsStatus.master = doc.data().masterStatementSavedAt ? true : false;
        state.smsStatus.designated = doc.data().designatedStatementSavedAt ? true : false;
        state.smsStatus.owner = doc.data().ownerStatementSavedAt ? true : false;

        state.smsStatus.hazards = doc.data().savedCompanyHazards ? true : false; 
        state.smsStatus.inductions = doc.data().savedCompanyInductionItems ? true : false; 
        state.smsStatus.smscontacts = doc.data().savedCompanyContacts ? true : false; 
        state.smsStatus.smspolicies = doc.data().savedCompanyPolicies ? true : false; 
        state.smsStatus.smssops = doc.data().savedCompanySOPS ? true : false; 
        state.smsStatus.smseops = doc.data().savedCompanyEOPS ? true : false; 
        state.smsStatus.smsmaintenance = doc.data().savedCompanyMaintenanceItems ? true : false; 
        state.smsStatus.smsstartups = doc.data().savedCompanyStartupItems ? true : false; 
        state.smsStatus.smsactivities = doc.data().savedCompanyActivityItems ? true : false; 
        state.smsStatus.smsshutdowns = doc.data().savedCompanyShutdownItems ? true : false; 
        state.smsStatus.smsjsas = doc.data().savedCompanyJSAItems ? true : false; 
        state.smsStatus.vesselinductionitems = doc.data().savedCompanyInductionItems ? true : false; 
        // state.smsStatus.passengerinductionitems = doc.data(). ? true : false; 
      });

      db.collection("vessels").doc(state.currentVesselID).collection('smsHazards').onSnapshot(function(querySnapshot) {
        // state.smsStatus.hazards = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.hazards = true;
      });

      // defects
      db.collection("vessels").doc(state.currentVesselID).collection('smsDefects').onSnapshot(function(querySnapshot) {
        // state.smsStatus.defects = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.defects = true;
      });

      // inductions
      db.collection("vessels").doc(state.currentVesselID).collection('smsInductionItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.inductions = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.inductions = true;
      });

      // contacts
      db.collection("vessels").doc(state.currentVesselID).collection('smsContacts').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smscontacts = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smscontacts = true;
      });

      // policies
      db.collection("vessels").doc(state.currentVesselID).collection('smsPolicies').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smspolicies = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smspolicies = true;
      });

      // SOPS
      db.collection("vessels").doc(state.currentVesselID).collection('smsSOPS').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smssops = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smssops = true;
      });

      //EOPS
      db.collection("vessels").doc(state.currentVesselID).collection('smsEOPS').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smseops = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smseops = true;
      });

      // maintenance
      db.collection("vessels").doc(state.currentVesselID).collection('smsMaintenanceItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smsmaintenance = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smsmaintenance = true;
      });

      //startups
      db.collection("vessels").doc(state.currentVesselID).collection('smsStartupItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smsstartups = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smsstartups = true;
      });      

      //activity notes
      db.collection("vessels").doc(state.currentVesselID).collection('smsActivityItems')
      .onSnapshot(function(querySnapshot) {
        // state.smsStatus.smsactivities = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smsactivities = true;
      });

      //shutdowns
      db.collection("vessels").doc(state.currentVesselID).collection('smsShutdownItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smsshutdowns = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smsshutdowns = true;
      });

      //jsas
      db.collection("vessels").doc(state.currentVesselID).collection('smsJSAItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.smsjsas = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.smsjsas = true;
      });

      // crew inductions
      db.collection("vessels").doc(state.currentVesselID).collection('smsVesselInductionItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.vesselinductionitems = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.vesselinductionitems = true;
      });      

      // passenger inductions
      db.collection("vessels").doc(state.currentVesselID).collection('smsPassengerInductionItems').onSnapshot(function(querySnapshot) {
        // state.smsStatus.passengerinductionitems = (querySnapshot.size > 0 ? true : false)
        if (querySnapshot.size > 0) state.smsStatus.passengerinductionitems = true;
      });

      //auditor feedback
      db.collection("vessels").doc(state.currentVesselID).collection('auditorFeedback').where("feedbackSeen", "==", false).onSnapshot(function(querySnapshot) {
        state.smsStatus.auditorFeedback = querySnapshot.size > 0 ? true : false;
        state.smsStatus.auditorFeedbackCount = querySnapshot.size;
      });
      
      // pending amsa audits
      db.collection("vessels").doc(state.currentVesselID).collection('amsaAudits').where("auditStatus", "==", "pending").onSnapshot(function(querySnapshot) {
        state.smsStatus.amsaAuditsPending = querySnapshot.size;
      });

    },

    SETDEMOSMSSTATUS: state => {

      // console.log(state.currentVesselID);

      db.collection("demo-vessels").doc(state.currentVesselID).onSnapshot(function(doc) {
          // console.log('overview saved at:',doc.data().overviewSavedAt);
          state.demoSmsStatus.overview = doc.data().overviewSavedAt ? true : false;
          state.demoSmsStatus.duty = doc.data().dutyCareAcceptedAt ? true : false;
          state.demoSmsStatus.master = doc.data().masterStatementSavedAt ? true : false;
          state.demoSmsStatus.designated = doc.data().designatedStatementSavedAt ? true : false;
      });

      // hazards
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsHazards')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.hazards = (querySnapshot.size > 0 ? true : false)
        });

      // contacts
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsContacts')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smscontacts = (querySnapshot.size > 0 ? true : false)
        });

      // policies
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsPolicies')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smspolicies = (querySnapshot.size > 0 ? true : false)
        });

      // SOPS
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsSOPS')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smssops = (querySnapshot.size > 0 ? true : false)
        });

      //EOPS
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsEOPS')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smseops = (querySnapshot.size > 0 ? true : false)
        });   

      //startups
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsStartupItems')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smsstartups = (querySnapshot.size > 0 ? true : false)
        });   

      // maintenance
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsMaintenanceItems')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.smsmaintenance = (querySnapshot.size > 0 ? true : false)
        });      

      //induction items
      db.collection("demo-vessels").doc(state.currentVesselID).collection('smsVesselInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.demoSmsStatus.vesselinductionitems = (querySnapshot.size > 0 ? true : false)
        });

      

    },

    SETLOCATION: (state, payload) => {
      state.currentLocation = payload;
    },



    SETTIMEBEGAN: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timeBegan: payload.timeBegan,
      });
    },

    GETTIMERRUNNING: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerRunning: payload.timerStatus,
      });
    },

    // new sea time page
    SETTIMERRUNNING: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerRunning: payload.running,
      });
    },
    SETTIMEROPEN: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerOpen: payload.open,
        timerOpenTimestamp: payload.openTime,
      });
    },
    SETTIMERSTART: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerStart: payload.startTime,
      });
    },
    SETTIMERSTOP: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerStop: payload.stopTime,
      });
    },
    SETTIMERTOTAL: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timerTotal: payload.totalTime,
      });
    },





    SETTIMESTOPPED: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        timeStopped: payload.timeStopped,
      });
    },
    SETRANDOMCODE: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        randomCode: payload.randomCode
      });
    },
    SETRUNNINGTIME: (state, payload) => {
      db.collection('userdata').doc(state.user.uid).update({
        runningTime: payload.runningTime
      });
    },

    // new seatime page
    GETISRUNNING: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          state.timerRunning = (doc.data().timerRunning ? doc.data().timerRunning : false);
        });
      }
    },
    GETISTIMEROPEN: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          state.timerOpen = (doc.data().timerOpen ? doc.data().timerOpen : false);
          state.timerOpenTimestamp = (doc.data().timerOpenTimestamp ? doc.data().timerOpenTimestamp.toDate(): '');
        });
      }
    },
    GETTIMERSTART: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          state.timerStart = (doc.data().timerStart ? doc.data().timerStart.toDate() : '');
        });
      }
    },
    GETTIMERSTOP: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          state.timerStop = (doc.data().timerStop ? doc.data().timerStop.toDate() : '');
        });
      }
    },
    GETTIMERTOTAL: (state) => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          state.timerTotal = (doc.data().timerTotal ? doc.data().timerTotal : '');
        });
      }
    },

    SETMYCOMPANIES: (state) => {
      db.collection('userdata').doc(state.user.uid).collection('myCompanies').orderBy('companyName','asc').onSnapshot(function(querySnapshot) {
        state.myCompanies = [];
        querySnapshot.forEach(function(doc) {
          state.myCompanies.push(doc.data());
        })
      });
    },

    SETCURRENTCOMPANY: (state, payload) => {
      // console.log('Payload',payload);
      state.currentCompany.name = payload.company.companyName;
      state.currentCompany.id = payload.company.companyID;
      state.currentCompany.state = payload.company.companyState;
      // console.log('Company set');
      store.commit('MYACTIVEVESSELS');
    }


  },
  actions: {
    resetStates: context => {
      context.commit('RESETSTATES');
    },
    updateUserState: context => {
      context.commit('UPDATEUSERSTATE');
    },
    setUser: context => {
      context.commit('SETUSER');
    },
    setIsAdmin: context => {
      context.commit('setIsAdmin');
    },
    SETMYCURRENTVESSELIDS: context => {
      context.commit('MYCURRENTVESSELIDS');
    },
    SETMYACTIVEVESSELS: context => {
      context.commit('MYACTIVEVESSELS');
    },
    addNewVessel: (context, payload) => {
      context.commit('addNewVessel',payload);
    },
    setMyCrew: context => {
      context.commit('SETMYCREW');
    },
    setMyJobs: context => {
      context.commit('SETMYJOBS');
    },
    addNewCrew: (context, payload) => {
      context.commit('ADDNEWCREW',payload);
    },
    clearCrew: context => {
      context.commit('CLEARCREW');
    },
    UPDATESELECTEDVESSEL: (context, payload) => {
      context.commit('SETSELECTEDVESSEL',payload);
    },
    SETCURRENTVESSEL: (context, payload) => {
      context.commit('SETCURRENTVESSEL',payload);
    },
    UPDATESELECTEDCOMPANYVESSEL: (context, payload) => {
      context.commit('SETSELECTEDCOMPANYVESSEL',payload);
    },
    UPDATEDEMOVESSEL: (context, payload) => {
      context.commit('SETDEMOVESSEL',payload);
    },
    CLEARSELECTEDVESSEL: context => {
      context.commit('CLEARSELECTEDVESSEL');
    },
    UPDATESMSSTATUS: context => {
      context.commit('SETSMSSTATUS')
    },
    UPDATEDEMOSMSSTATUS: context => {
      context.commit('SETDEMOSMSSTATUS')
    },
    UPDATECOMPANYSTATUS: context => {
      context.commit('SETCOMPANYSTATUS')
    },
    UPDATELOCATION: (context, payload) => {
      context.commit('SETLOCATION',payload);
    },
    SETSUBSCRIPTIONINFO: context => {
      context.commit('SETSUBSCRIPTIONINFO');
    },
    SETMYVESSELSWITHCURRENTDEFECTS: context => {
      context.commit('SETMYVESSELSWITHCURRENTDEFECTS');
    },

    SETTIMEBEGAN: (context, payload) => {
      context.commit('SETTIMEBEGAN',payload);
    },
    SETTIMESTOPPED: (context, payload) => {
      context.commit('SETTIMESTOPPED',payload);
    },
    SETRANDOMCODE: (context, payload) => {
      context.commit('SETRANDOMCODE',payload);
    },
    SETRUNNINGTIME: (context, payload) => {
      context.commit('SETRUNNINGTIME',payload);
    },
    SETONLINESTATUS: (context) => {
      context.commit('SETONLINESTATUS');
    },
    SETDATASTATUS: (context) => {
      context.commit('SETDATASTATUS');
    },
    SETMYCURRENTVESSELDATA: context => {
      context.commit('MYCURRENTVESSELDATA');
    },

    // new seatime page  
    GETISRUNNING: context => {
      context.commit('GETISRUNNING');
    },  
    GETISTIMEROPEN: context => {
      context.commit('GETISTIMEROPEN');
    },  
    GETTIMERSTART: context => {
      context.commit('GETTIMERSTART');
    }, 
    GETTIMERSTOP: context => {
      context.commit('GETTIMERSTOP');
    }, 
    GETTIMERTOTAL: context => {
      context.commit('GETTIMERTOTAL');
    }, 
    SETTIMERRUNNING: (context, payload) => {
      context.commit('SETTIMERRUNNING',payload);
    },
    SETTIMEROPEN: (context, payload) => {
      context.commit('SETTIMEROPEN',payload);
    },
    SETTIMERSTART: (context, payload) => {
      context.commit('SETTIMERSTART',payload);
    },
    SETTIMERSTOP: (context, payload) => {
      context.commit('SETTIMERSTOP',payload);
    },
    SETTIMERTOTAL: (context, payload) => {
      context.commit('SETTIMERTOTAL',payload);
    },

    SETUSERPROFILE: context => {
      context.commit('SETUSERPROFILE');
    },





    // NEW ACTIONS ==================

    SETMYVESSELS: context => {
      context.commit('SETMYVESSELS');
    },
    SETMYCREWEDVESSELDATA: context => {
      context.commit('MYCREWEDVESSELDATA');
    },
    SETCURRENTLOGVESSEL: (context, payload) => {
      context.commit('SETCURRENTLOGVESSEL',payload);
    },
    LOADVESSELSTARTUPITEMS: (context, payload) => {
      context.commit('LOADVESSELSTARTUPITEMS',payload);
    },
    LOADALLVESSELCREW: context => {
      context.commit('LOADALLVESSELCREW');
    },
    LOADDIVEPLAN: context => {
      context.commit('LOADDIVEPLAN');
    },
    SETVESSELINDUCTION: (context, payload) => {
      context.commit('SETVESSELINDUCTION',payload);
    },
    SETALLMYCREW: context => {
      context.commit('SETALLMYCREW');
    },
    SETALLMYJOBS: context => {
      context.commit('SETALLMYJOBS');
    },
    SETMYTRAINERS: context => {
      context.commit('SETMYTRAINERS');
    },
    LOADDASHBOARD: context => {
      context.state.stateLoaded = false;
      setTimeout(() => {
        context.commit('LOADDASHBOARD')
      }, 3000)
    },
    // SETISAUDITOR: context => {
    //   context.commit('SETISAUDITOR');
    // }
    UPDATEMYCOMPANIES: context => {
      context.commit('SETMYCOMPANIES');
    },
    SETCURRENTCOMPANY: (context, payload) => {
      context.commit('SETCURRENTCOMPANY',payload);
    },
    UPDATEALLCREWEDVESSELS: context => {
      context.commit('UPDATEALLCREWEDVESSELS');
    },


  }
});
