<template>
  <header>
    <nav>
      <router-link to="/" class="button">Home</router-link>
      <router-link to="dashboard" class="button" v-if="user">Dashboard</router-link>
      <router-link to="ports" class="button" v-if="user">Ports</router-link>
      <router-link to="sign-in" class="button" v-if="!user">Sign-in</router-link>
      <router-link to="sign-up" class="button" v-if="!user">Sign-up</router-link>
      <!-- <a class="button" v-on:click="signOut" v-if="user">Sign-out</a> -->
    </nav>
  </header>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
// import 'firebase/firestore';
// import 'firebase/functions';

export default {
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    signOut: function() {
      firebase.auth()
        .signOut()
        .then(() => {
          this.$router.replace('sign-in');
        });
    }
  }
};
</script>

<style lang="scss">
// Basic styles are pulled in from the Bulma framework https://bulma.io/
// These style tags could be omitted as they arre not used.
</style>