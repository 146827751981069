export const routes = [
    // () => import(/* webpackChunkName: "group-dashboard" */ '../components/')

    // { path: '', name: 'home', component: () => import( '../components/Home.vue') },

    {
        path: '/',
        name: 'new-home',
        component: () => import(/* webpackChunkName: "home" */ '../components/NewHome.vue')
    },

    {
        path: '/pricing',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Pricing')
    },

    {
        path: '/plan-logs',
        name: 'PlanLogs',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PlanLogs')
    },

    {
        path: '/plan-logs-sms',
        name: 'PlanLogsSms',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PlanLogsSms')
    },

    {
        path: '/plan-pro',
        name: 'PlanPro',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PlanPro')
    },

    {
        path: '/plan-fleet',
        name: 'PlanFleet',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PlanFleet')
    },

    {
        path: '/faqs',
        name: 'Faqs',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Faqs')
    },

    {
        path: '/useful-links',
        name: 'UsefulLinks',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/UsefulLinks')
    },

    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/AboutUs')
    },

    {
        path: '/saas-terms',
        name: 'SaasTerms',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/SaasTerms')
    },

    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PrivacyPolicy')
    },

    {
        path: '/data-security-policy',
        name: 'DataSecurityPolicy',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/DataSecurityPolicy')
    },

    {
        path: '/account-data',
        name: 'AccountData',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/AccountData')
    },

    {
        path: '/support',
        name: 'Support',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Support')
    },

    {
        path: '/book-a-demo',
        name: 'BookADemo',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/BookADemo')
    },

    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Feedback')
    },

    {
        path: '/fast-track-view-sms',
        name: 'FastTrackViewSms',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/FastTrackViewSms')
    },

    {
        path: '/custom-log-response',
        name: 'CustomLogResponse',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/CustomLogResponse')
    },

    {
        path: '/custom-sms-review',
        name: 'CustomSmsReview',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/CustomSmsReview')
    },

    // { path: '/explore-features', name: 'ExploreFeatures', component: () => import(/* webpackChunkName: "group-info" */ '../components/ExploreFeatures') },

    {
        path: '/preso',
        name: 'Preso',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Preso')
    },

    {
        path: '/download',
        name: 'Download',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Download')
    },

    {
        path: '/leave-testimonial',
        name: 'LeaveTestimonial',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/LeaveTestimonial')
    },

    {
        path: '/new-vessel-info',
        name: 'NewVesselInfo',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/NewVesselInfo')
    },

    {
        path: '/conversations',
        name: 'Conversations',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Conversations')
    },

    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Welcome')
    },

    {
        path: '/new-vessel-info-response',
        name: 'NewVesselInfoResponse',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/NewVesselInfoResponse')
    },

    {
        path: '/request-sms-review',
        name: 'RequestSmsReview',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/RequestSmsReview')
    },

    {
        path: '/user-profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/UserProfile')
    },

    {
        path: '/sms-review-request',
        name: 'SmsReviewRequest',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/SmsReviewRequest')
    },

    {
        path: '/short',
        name: 'Short',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/Short')
    },

    {
        path: '/personal-timer-export',
        name: 'PersonalTimerExport',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/PersonalTimerExport')
    },

    {
        path: '/timed-activity-export',
        name: 'TimedActivityExport',
        component: () => import(/* webpackChunkName: "group-info" */ '../components/TimedActivityExport')
    },

    {
        path: '/user-guide-start',
        name: 'UserGuideStart',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideStart')
    },

    {
        path: '/user-guide-vessels',
        name: 'UserGuideVessels',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideVessels')
    },

    {
        path: '/user-guide-fleets',
        name: 'UserGuideFleets',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideFleets')
    },

    {
        path: '/user-guide-crew',
        name: 'UserGuideCrew',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideCrew')
    },

    {
        path: '/user-guide-app',
        name: 'UserGuideApp',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideApp')
    },

    // { path: '/user-guide-connections', name: 'UserGuideConnections', component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideConnections'), },

    {
        path: '/user-guide-custom-logs',
        name: 'UserGuideCustomLogs',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideCustomLogs')
    },

    {
        path: '/user-guide-obligations',
        name: 'UserGuideObligations',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideObligations')
    },

    {
        path: '/user-guide-subscriptions',
        name: 'UserGuideSubscriptions',
        component: () => import(/* webpackChunkName: "userguides" */ '../user-guides/UserGuideSubscriptions')
    },

    { path: '*', redirect: '/404' },

    { path: '/404', name: '404', component: () => import('../components/Error404') }

    // { path: '*', redirect: '/'  }
];
